const ARCHIVE = "archive";
const DAY = "day";

const RoutesValues = {
  root: "/",
  signIn: "/sign-in",
  signUp: "/sign-up",
  archiveDynamic: `/${ARCHIVE}/:monthIndex`,
  archive: `/${ARCHIVE}`,
  tracker: "/tracker",
  dayDynamic: `/${DAY}/:date`,
  day: `/${DAY}`,
  notFound: "/404",
};

export default RoutesValues;
