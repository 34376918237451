import React from "react";
import { useWaterTrackerInitializer } from "../WaterTracker.hooks";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Month } from "../DataToView";
import MonthControls from "../MonthControls/MonthControls";
import { MonthSkeleton } from "../DataToView/Month/Month.skeleton";
import { MonthControlsSkeleton } from "../MonthControls/MonthControls.skeleton";
import classes from "./Archive.module.css";
import { formatStringToDate } from "../../../Utils/Time.utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Reducers/Store";
import { setTimeTraverse } from "../../../Reducers";

/**
 * Component for displaying month and month navigation
 */
const Archive: React.FC = () => {
  // get params object to access date
  const params = useParams();

  const { timeTraverse } = useSelector(
    (state: RootState) => state.waterTracker
  );

  const dispatch = useDispatch();

  // validate date string and redirect to 404 if not valid
  const regex = /^\d{1,2}-\d{4}$/;
  const match = regex.test(
    typeof params.monthIndex === "string" ? params.monthIndex : ""
  );

  const date =
    match && params.monthIndex
      ? formatStringToDate(params.monthIndex as string, "MM-YYYY")
      : null;

  // initialize all necessary data for water tracker and provide them to components
  const { initialLoading } = useWaterTrackerInitializer(date);

  // if month data is loading then show skeleton
  const MonthOnLoading = initialLoading ? MonthSkeleton : Month;

  // if data is loading then show skeleton
  const MonthControlsEl = initialLoading
    ? MonthControlsSkeleton
    : MonthControls;

  if (date && (timeTraverse.month === null || timeTraverse.year === null)) {
    dispatch(
      setTimeTraverse({ year: date.getFullYear(), month: date.getMonth() })
    );
  }

  return (
    <div className={`lg:my-3 my-0 ${classes["wrapper"]}`}>
      <Grid container spacing={{ xs: 1 }} alignItems={"center"}>
        {/* Initializer will redirect to 404 page if data is nullish */}
        {date && <MonthControlsEl date={date} />}
        <MonthOnLoading />
      </Grid>
    </div>
  );
};

export { Archive };
